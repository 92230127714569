import clsx from 'clsx'
import {FC} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from 'setup'
import {UserModel} from '../../../../app/features/auth/models/UserModel'
import SetupGuideHeader from '_metronic/partials/layout/setup-guide/SetupGuideHeader'
// import SubscriptionPlan from '_metronic/partials/layout/subscription-plan/SubscriptionPlan'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import SupportTicket from '_metronic/partials/layout/support'
import PointOfSale from '_metronic/partials/layout/pos/PointOfSale'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-40px symbol-md-40px'

const TopBar: FC = () => {
  // const {config} = useLayout()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* <PointOfSale /> */}
      <SupportTicket />
      {/* <SubscriptionPlan /> */}
      {/* SETUP GUIDE */}
      <SetupGuideHeader />

      {/* SETTINGS */}
      <div
        className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div
            className='symbol-label'
            style={{backgroundImage: `url('${toAbsoluteUrl('/media/avatars/avatar.svg')}')`}}
          >
            <span className='avatar-name'>{user?.avatarText}</span>
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {/* begin::Aside Toggler */}
    </div>
  )
}

export default TopBar
