import {FC} from 'react'
import {useFormikContext} from 'formik'
import Card from '_core/components/Card'
import {Checkbox, MultilineTextField} from '_core/forms'
import {KTSVG} from '_metronic/helpers'
import CustomerClass from './CustomerClass'
import CustomerTags from './CustomerTag/CustomerTags'
import CustomerDetail from './CustomerDetail'

type Props = {
  onTagDialogOpen: (prop: boolean) => any
}

const CustomerOverview: FC<Props> = ({onTagDialogOpen}) => {
  const {
    values: {tagIds, id},
  } = useFormikContext<any>()

  return (
    <Card title='Customer Overview' className='card-xl-stretch mb-5 mb-xl-8'>
      <CustomerDetail disabled={id} isPhoneIsNotRequired />
      <div className='row'>
        <div className='col-xl-6'>
          <label className='form-label fs-6 fw-bold text-dark'>Class</label>
          <CustomerClass />
        </div>
        <div className='col-xl-4'>
          <label className='form-label fs-6 fw-bold text-dark'>Tags</label>
          <CustomerTags tagIds={tagIds} name='tagIds' />
        </div>
        <div className='col-xl-2 pt-6 mb-4'>
          <button
            type='button'
            onClick={() => onTagDialogOpen(true)}
            className='btn btn-sm btn-primary'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add Tag
          </button>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='col-xl-12'>
          <label className='form-label fs-6 fw-bold text-dark '>Notes</label>
          <MultilineTextField id='txtNotes' className='mb-5' rows={5} name='note' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-check form-check-inline form-check-solid me-5'>
          <Checkbox id='chkCustomerAgreedEmails' name='acceptsMarketing' />
          <span className='fw-bold ps-2 fs-6'>Customer agreed to receive marketing emails</span>
        </label>
      </div>
    </Card>
  )
}
export default CustomerOverview
