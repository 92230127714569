import {
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  OperationVariables,
  QueryOptions,
} from '@apollo/client'
import apolloClient from 'apollo-client'

class GraphqlService {
  async _mutate(query: DocumentNode, variables?: QueryOptions) {
    const config = {
      mutation: query,
      variables,
    }

    return await apolloClient.mutate(config)
  }

  async _query(query: DocumentNode, variables?: OperationVariables) {
    const config = {
      query,
      variables,
    }
    return await apolloClient.query(config)
  }

  async _signalQuery(query: DocumentNode, signal: any, variables?: OperationVariables) {
    const config: QueryOptions = {
      query,
      variables,
      context: {
        fetchOptions: {
          signal,
        },
      },
    }
    return await apolloClient.query(config)
  }

  private return({errors, data}: ApolloQueryResult<any> | FetchResult) {
    if (errors) {
      throw errors
    }

    return data
  }
}

export default GraphqlService
