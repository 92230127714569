import {valueType} from './../../wine-clubs/models/CustomerChoiceModel'
import {gql} from '@apollo/client'

export const CREATE_DRAFT_ORDER = gql`
  mutation CreateDraftOrder($draftOrder: DraftOrderInput!) {
    createDraftOrder(input: {draftOrder: $draftOrder}) {
      draftOrder {
        id
      }
    }
  }
`

export const UPDATE_DRAFT_ORDER = gql`
  mutation UpdateDraftOrder($draftOrder: DraftOrderPatch!, $id: UUID!) {
    updateDraftOrder(input: {patch: $draftOrder, id: $id}) {
      clientMutationId
    }
  }
`

export const REMOVE_DRAFT_ORDER_DISCOUNT = gql`
  mutation RemoveDraftOrderDiscount($discountCodeId: UUID!, $id: UUID!) {
    removeDraftOrderDiscount(input: {pDiscountCodeId: $discountCodeId, pDraftOrderId: $id}) {
      clientMutationId
    }
  }
`

export const GET_DRAFT_ORDER = gql`
  query GetDraftOrder($id: UUID!) {
    draftOrder(id: $id) {
      id
      customerId
      createdAt
      status
      createdAt
      totalPrice
      totalDiscounts
      wineClubDiscounts
      subtotalPrice
      totalTax
      shippingPrice
      name
      shippingAddressId
      billingAddressId
      shippingRateId
      paymentMethodId
      orderDeliveryMethod
      isFreeShipping
      note
      crv
      salesTax
      discounts {
        nodes {
          amount
          code
          isPromoCode
          discountCodeId
          isShipping
          isWineClub
          name
          valueType
          maximumAmount
          discountValue
        }
      }
      shippingRate {
        id
        carrierType
        originalPrice
        price
        serviceId
        serviceType
        title
        handlingFee
        providerShippingPrice
      }
      customer {
        id
        firstName
        email
        lastName
        phone
        hasWineClub
      }
      billingAddress {
        addressLine1
        addressLine2
        city
        id
        phone
        state {
          code
          name
        }
        country {
          code
        }
        zipCode
      }
      shippingAddress {
        addressLine1
        addressLine2
        city
        id
        phone
        state {
          code
          name
        }
        country {
          code
        }
        zipCode
      }
      draftOrderItems {
        nodes {
          id
          variantId
          quantity
          productId
          product {
            name
            imageSrc
          }
          variant {
            name
            available
            price
            discountPrice
            shipAvailableForAdminPortal
            pickupAvailableForAdminPortal
            inventoryItem {
              canAllowNegativeInventory
            }
          }
        }
      }
      paymentMethod {
        id
        card {
          brand
          last4
          expYear
          expMonth
        }
        isPrimary
        address {
          id
          firstName
          lastName
          addressLine2
          addressLine1
          city
          country {
            code
          }
          state {
            code
          }
          zipCode
        }
      }
    }
  }
`

export const COMPLETE_DRAFT_ORDER = gql`
  mutation CreateOrder($order: OrderInput!, $draftOrder: UpdateDraftOrderInput!) {
    createOrder(input: {order: $order}) {
      order {
        id
      }
    }

    updateDraftOrder(input: $draftOrder) {
      clientMutationId
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder($order: OrderInput!) {
    createOrder(input: {order: $order}) {
      order {
        id
      }
    }
  }
`

export const DELETE_DRAFT_ORDER_SHIPPING = gql`
  mutation DeleteDraftOrderShipping($draftOrder: DeleteDraftOrderShippingInput!) {
    deleteDraftOrderShipping(input: $draftOrder) {
      clientMutationId
    }
  }
`
