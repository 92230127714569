import * as yup from 'yup'
import {useEffect, useState} from 'react'
import {useFormik, FormikProvider} from 'formik'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import * as auth from 'app/features/auth/redux/AuthRedux'
import {showMessage} from 'app/redux/messageSlice'
import storeService from 'app/services/StoreService'
import {MaskedNumberField} from '_core/forms'
import {Button} from '_core'
import {MonthYearPicker} from '_core/forms/DateTimeField'
import {toAbsoluteUrl} from '_metronic/helpers'
import SVG from 'react-inlinesvg'
import omit from 'lodash/omit'
import {getMonth} from 'app/format/date'
import trim from 'lodash/trim'
import {simpleCurrency} from 'app/format/currency'

const StoreDetailsSchema = yup.object().shape({
  number: yup
    .string()
    .trim()
    .min(18, 'Please enter valid card number')
    .required('Please enter a Card Number'),
  expDate: yup.string().required('Please select a expiration date'),
  cvv: yup
    .string()
    .trim()
    .min(3, 'CVV not less than 3')
    .max(4, 'CVV not greater than 4')
    .required('Enter a security code'),
})

const initialValues = {
  expDate: '',
  cvv: '',
  number: '',
}

function PaymentDetails() {
  const dispatch = useDispatch()
  const location: any = useLocation()
  const history = useHistory()
  const [error, setError] = useState('')
  const {state: store}: any = location
  const [fee, setFee] = useState({
    fee: '',
    transactionFee: '',
    feeType: '',
  })

  useEffect(() => {
    const fetch = async () => {
      const data = await storeService.getAccountCreationRequest(store.token)
      if (data) {
        setFee({...fee, transactionFee: data.transactionFee, feeType: data.feeType, fee: data.fee})
      }
    }
    if (store.token) {
      fetch()
    }
    if (!location.state?.email) {
      history.push('/store/create')
    }
  }, [location, history])

  const formik = useFormik({
    initialValues,
    validationSchema: StoreDetailsSchema,
    onSubmit: handleSave,
  })

  async function handleSave(model: any, {setSubmitting}: any) {
    try {
      setError('')
      setSubmitting(true)
      const expMonth: any = new Date(model.expDate).getMonth() + 1
      const expYear: any = new Date(model.expDate).getFullYear()
      const payment = {...omit(model, 'expDate')}
      const card = {
        ...payment,
        expMonth: trim(expMonth.toString()),
        expYear: trim(expYear.toString()),
        cvv: trim(model.cvv),
      }
      const out = await storeService.create({
        ...store,
        card,
      })
      if (out.status === 200) {
        formik.resetForm()
        dispatch(auth.actions.signUp(out.authToken.accessToken))
      } else {
        if (out.errors?.card) {
          const err = JSON.parse(out.errors?.card)
          setError(err.message)
        } else {
          setError(out.errors)
        }
      }
    } catch (error) {
      console.error(error)
      dispatch(
        showMessage({
          text: 'An error occurred while creating store',
          variant: 'error',
        })
      )
    } finally {
      setSubmitting(false)
    }
  }

  const currentDate = new Date()
  const nextMonthFirstDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
  const month = getMonth(nextMonthFirstDate, 0)

  return (
    <FormikProvider value={formik}>
      <div className='d-flex p-15 free-trail-card rounded-10 w-100 w-md-550px mx-auto'>
        <form className='form w-100 ' onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Enter your payment details</h1>
          </div>
          <div className='card border border-3 shadow-sm bg-transparent mb-10'>
            <div className='bg-secondary p-5 m-5 rounded'>
              <SVG
                src={toAbsoluteUrl('/media/icons/corksy/exclamation-circle.svg')}
                className='svg-icon-1'
              />
              <span className='pt-2 ms-3 text-dark'>
                First Payment to be deducted on {month} 1st, {nextMonthFirstDate.getFullYear()}
              </span>
            </div>
            <div className='d-flex justify-content-between mx-5 mb-4'>
              <div className='fw-bolder'>Corksy Plus</div>
              <div className='text-info'>
                <span className='fw-bolder'>{infoText(fee)} /mon*</span>
              </div>
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bold text-dark required'>Card Number</label>
            <MaskedNumberField
              name='number'
              placeholder='Card Number'
              format='#### #### #### ####'
            />
          </div>
          {/* <div className='fv-row mb-10 w-100'>
            <label className='form-label fs-6 fw-bold text-dark required'>
              Name or Business on Card
            </label>
            <TextField id='txtCardName' type='text' placeholder='Card name' name='name' />
          </div> */}

          <div className='fv-row mb-7 row'>
            <div className='col-6'>
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bold text-dark required'>
                  Expiration Date
                </label>
                <MonthYearPicker
                  id='sltExpDate'
                  name='expDate'
                  placeholder='Expiration Date'
                  minDate='today'
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bold text-dark required'>Security Code</label>
                <MaskedNumberField
                  name='cvv'
                  placeholder='Security Code'
                  format='####'
                  allowNegative={false}
                  className='py-3'
                />
              </div>
            </div>
            {error && (
              <div className='fv-plugins-message-container invalid-feedback pt-2'>
                <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
                <span role='alert'>{error}</span>
              </div>
            )}
          </div>
          <div className='text-center'>
            <Button
              id='btnSave'
              type='submit'
              title='Complete Sign up '
              isSubmitting={formik.isSubmitting}
              isValid={formik.isValid}
              className='btn-lg btn-primary w-100'
              endIcon='/media/icons/corksy/arrow-right.svg'
            />
          </div>
          <div className='pt-5'>
            <span className='text-sm'>
              By clicking "Complete Signup" you agree to the{' '}
              <a
                href='https://corksy.io/terms-and-conditions/'
                rel='noreferrer noopener'
                target='_blank'
              >
                Corksy Subscription Agreement
              </a>{' '}
              and{' '}
              <a href='https://corksy.io/privacy-policy/' rel='noreferrer noopener' target='_blank'>
                Privacy Policy
              </a>
            </span>
          </div>
          <div className='text-muted fs-7 pt-5'>
            <u>*{infoText2(fee)}</u>
          </div>
        </form>
      </div>
    </FormikProvider>
  )
}
export default PaymentDetails

const infoText = (fee: any) => {
  if (fee.fee > 0 && fee.transactionFee > 0) {
    if (fee.feeType === 'MINIMUM') {
      return `${fee.transactionFee}%  or  ${simpleCurrency(fee.fee)}`
    } else {
      return `${fee.transactionFee}%  and  ${simpleCurrency(fee.fee)}`
    }
  } else if (fee.transactionFee > 0) {
    return `${fee.transactionFee}%`
  } else if (fee.fee > 0) {
    return `${simpleCurrency(fee.fee)}`
  } else {
    return '2.99% or $149.99'
  }
}

const infoText2 = (fee: any) => {
  if (fee.fee > 0 && fee.transactionFee > 0) {
    if (fee.feeType === 'MINIMUM') {
      return `${fee.transactionFee}% of Transactions or ${simpleCurrency(
        fee.fee
      )} monthly, whichever is higher.`
    } else {
      return `${fee.transactionFee}% of Transactions and ${simpleCurrency(fee.fee)} monthly.`
    }
  } else if (fee.transactionFee > 0) {
    return `${fee.transactionFee}% of Transactions monthly.`
  } else if (fee.fee > 0) {
    return `${simpleCurrency(fee.fee)} of Transactions monthly.`
  } else {
    return '2.99% of Transactions or $149.99 monthly, whichever is higher.'
  }
}
