import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import map from 'lodash/map'
import PaymentListItem from './PaymentListItem'
import {getPaymentMethods, resetPaymentState} from 'app/features/orders/redux/paymentMethodSlice'
import Card from '_core/components/Card'
import {hideLoader} from 'app/redux/loaderSlice'
import PaymentFormDialog from '../PaymentFormDialog'
import draftOrderPaymentService from 'app/services/DraftOrderPaymentService/DraftOrderPaymentService'
import {showMessage} from 'app/redux/messageSlice'

type Props = {
  paymentMethodList: Array<any>
  customerId: string
  addressList: Array<any>
  isAddressFetch?: boolean
}
const PaymentList: FC<Props> = ({customerId, paymentMethodList, addressList, isAddressFetch}) => {
  const [showDialog, setShowDialog] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (customerId) {
      dispatch(getPaymentMethods(customerId))
    }
  }, [dispatch, customerId])

  useEffect(() => {
    return () => {
      dispatch(resetPaymentState())
      dispatch(hideLoader())
    }
  }, [dispatch])

  const handleClose = () => {
    setShowDialog(false)
    dispatch(hideLoader())
  }

  const handleOpen = () => {
    setShowDialog(true)
    dispatch(hideLoader())
  }

  const handleUpdatePaymentMethod = async (paymentMethodId: string) => {
    try {
      await draftOrderPaymentService.updatePrimaryPaymentMethod(paymentMethodId)
      dispatch(getPaymentMethods(customerId))
      dispatch(
        showMessage({
          text: 'Customer Payment has been set as primary successfully',
          variant: 'success',
        })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while set primary payment method',
          variant: 'error',
        })
      )
    }
  }
  return (
    <Card
      title='Payment Methods'
      className='card-xl-stretch mb-5 mb-xl-8'
      toolbar={
        paymentMethodList.length ? (
          <button
            id='btnAddNewPayment'
            type='button'
            onClick={handleOpen}
            className='btn btn-sm btn-secondary '
          >
            Add New Payment Method
          </button>
        ) : null
      }
    >
      <div className='py-4'>
        <div className='row'>
          {map(paymentMethodList, (payment: any) => (
            <PaymentListItem
              key={payment.id}
              payment={payment}
              onSelect={handleUpdatePaymentMethod}
              address={payment.address}
              customerId={customerId}
            />
          ))}
          {paymentMethodList.length === 0 && (
            <div className='card notice d-flex bg-light rounded border-secondary border border-dashed '>
              <div className='d-flex justify-content-center m-8 p-6'>
                <div>
                  <div className='mb-3'>
                    No payment method found. Create your first payment method !
                  </div>
                  <span className='d-flex justify-content-center'>
                    <button
                      id='btnAddPayment'
                      type='button'
                      onClick={handleOpen}
                      className='btn explore-btn-outline btn-sm  '
                    >
                      Add New Payment Method
                    </button>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDialog && (
        <PaymentFormDialog
          isAddressFetch={isAddressFetch}
          onClose={handleClose}
          customerId={customerId}
          addressList={addressList}
        />
      )}
    </Card>
  )
}
export default PaymentList
