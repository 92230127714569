/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import analyticsService from 'app/services/AnalyticsService'
import {useLayout} from '_metronic/layout/core'
import {AsideMenuItemWithAction} from './AsideMenuItemWithAction'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import config from 'config'

export function AsideMenuMain() {
  const handleAnalytics = async (e: any) => {
    e.preventDefault()
    const url = await analyticsService.url()
    window.open(url)
  }

  const {isPosMenuEnable} = useLayout()

  const store = useSelector<RootState>(({auth}) => auth.user?.store) as any

  const url = useMemo(() => {
    let host = 'https://'

    if (store && store.domain) {
      host = `https://${store.domain}.`
    }

    const baseURLs: any = {
      production: 'pos.corksy.io',
      staging: 'pos-staging.corksy.io',
      demo: 'pos-demo.corksy.io',
      dev: 'localhost',
    }

    let url = `${host}${baseURLs[config.ENVIRONMENT]}`
    return url
  }, [store])

  const handleNavigatePOS = () => {
    window.open(url, '_blank')
  }

  return (
    <>
      <div className='menu-header-icon'>
        <div className='d-flex justify-content-between'>
          <div className='menu-item'>
            <div className='menu-content pt-6'>
              <span className='menu-section'>Main</span>
            </div>
          </div>
          <div className='menu-item'>
            <div className='menu-link'>
              <div
                id='kt_aside_toggle'
                className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                data-kt-toggle='true'
                data-kt-toggle-state='active'
                data-kt-toggle-target='body'
                data-kt-toggle-name='aside-minimize'
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr079.svg'}
                  className={'svg-icon-1 rotate-180'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AsideMenuItem
        id='linkHome'
        to='/home'
        icon='/media/icons/corksy/home.svg'
        title='Home'
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        id='linkDashboard'
        to='/dashboard'
        icon='/media/icons/corksy/dashboard1.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}

      <AsideMenuItemWithSub
        id='linkProducts'
        to='/products'
        title='Products'
        icon='/media/icons/corksy/product-solid.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem id='linkProductsAll' to='/products' title='Products' hasBullet={true} />
        <AsideMenuItem
          id='linkInventory'
          to='/products/inventory'
          title='Inventory'
          hasBullet={true}
        />
        <AsideMenuItem
          id='linkCollections'
          to='/products/collections'
          title='Collections'
          hasBullet={true}
        />
        <AsideMenuItem id='linkBrandsAll' to='/products/brands' title='Brands' hasBullet={true} />
        {/* <AsideMenuItem
          id='linkBundlessAll'
          to='/products/bundles'
          title='Bundles'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        id='linkOrders'
        to='/orders'
        title='Orders'
        icon='/media/icons/corksy/order-solid.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem id='linkOrdersAll' to='/orders' title='Orders' hasBullet={true} />
        <AsideMenuItem
          id='fulfillments'
          to='/orders/bulk/fulfillments'
          title='Bulk Fulfillment'
          hasBullet={true}
        />
        <AsideMenuItem id='linkDrafts' to='/orders/draft' title='Drafts' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        id='linkCustomers'
        to='/customers'
        icon='/media/icons/corksy/customers.svg'
        title='Customers'
        fontIcon='bi bi-people'
      />

      <AsideMenuItem
        id='linkDiscounts'
        to='/discounts'
        icon='/media/icons/corksy/discount-solid.svg'
        title='Discounts'
        fontIcon='bi bi-people'
      />

      <AsideMenuItemWithSub
        id='linkEvents'
        to='/wine_tastings'
        title='Wine tasting'
        icon='/media/icons/corksy/wine-tasting-solid.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem id='link-events' to='/wine_tastings' title='Packages' hasBullet={true} />

        <AsideMenuItem
          id='linkCurrentBooking'
          to='/wine_tastings/bookings'
          title='Current Booking'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        id='linkWineClubs'
        to='/wine_clubs'
        icon='/media/icons/corksy/wine-club-solid.svg'
        title='Wine clubs'
      >
        <AsideMenuItem id='linkWineClubs' to='/wine_clubs' title='Wine clubs' hasBullet={true} />
        <AsideMenuItem
          id='linkClubMemberships'
          to='/wine_clubs/memberships/list'
          title='Memberships'
          hasBullet={true}
        />
        {/* <AsideMenuItem id='linkOrders' to='/wine_clubs/orders' title='Orders' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        id='linkWineSubscription'
        to='/wine/subscriptions/products'
        icon='/media/icons/corksy/subscription-solid.svg'
        title='Subscriptions'
        fontIcon='bi bi-people'
      >
        <AsideMenuItem
          id='linkWineSubscriptions'
          to='/wine/subscriptions/products'
          title='Products & Collections'
          hasBullet={true}
        />
        <AsideMenuItem
          id='linkWineMemberships'
          to='/subscriptions/members'
          title='Memberships'
          hasBullet={true}
        />
        <AsideMenuItem
          id='linkWineMemberships'
          to='/subscriptions/members/orders'
          title='Orders'
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* 
      <AsideMenuItem
        id='linkLoyalty'
        to='/loyalty'
        icon='/media/icons/corksy/gift.svg'
        title='Loyalty'
        fontIcon='bi bi-gift'
      /> */}
      {/* <AsideMenuItem
        id='linkDiscounts'
        to='/message'
        icon='/media/icons/corksy/messages-solid.svg'
        title='Messages'
        fontIcon='bi bi-people'
      /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sales Channels</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        id='linkOnlineStore'
        to='/online_store'
        icon='/media/icons/corksy/store.svg'
        title='Online Store'
      >
        <AsideMenuItem id='linkReports' to='/online_store' title='Themes' hasBullet={true} />
      </AsideMenuItemWithSub>

      {isPosMenuEnable && (
        <AsideMenuItemWithSub
          id='linkPOS'
          title='Point of Sale'
          icon='/media/icons/corksy/pos.svg'
          fontIcon='bi bi-people'
          to='/products/custom-tax'
        >
           <AsideMenuItem
          id='linkCustomTax'
          to='/products/custom-tax'
          title='Custom Tax'
          hasBullet={true}
          />
         <AsideMenuItemWithAction
            id='linkOpenPos'
            onClick={handleNavigatePOS}
            title='Open POS'
            hasBullet={true}
          />
        </AsideMenuItemWithSub >
      )}

      <AsideMenuItemWithSub
        id='linkMarketing'
        to='/marketing/segments'
        icon='/media/icons/corksy/marketing-solid.svg'
        title='Marketing'
      >
        <AsideMenuItem
          id='linkSegmentAll'
          to='/marketing/segments'
          title='Segments'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        id='linkAnalytics'
        to='/analytics'
        icon='/media/icons/corksy/report.svg'
        title='Analytics'
        fontIcon='bi bi-bar-chart-line'
      >
        <AsideMenuItemWithAction
          id='linkAnalytics'
          title='Analytics'
          hasBullet={true}
          onClick={handleAnalytics}
        />
        <AsideMenuItem id='linkReports' to='/analytics/reports' title='Reports' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        id='linkAnalytics'
        to='/reports'
        icon='/media/icons/corksy/reports.svg'
        title='Reports'
        fontIcon='bi bi-bar-chart-line'
      >
        <AsideMenuItem id='linkReports' to='/reports/sales' title='Sales' hasBullet={true} />
        <AsideMenuItem
          id='linkReports'
          to='/reports/inventory'
          title='Inventory'
          hasBullet={true}
        />
        <AsideMenuItem
          id='linkReports'
          to='/reports/marketing'
          title='Marketing'
          hasBullet={true}
        />
        <AsideMenuItem id='linkReports' to='/reports/wineclub' title='Wine club' hasBullet={true} />
        {isPosMenuEnable && (
          <AsideMenuItem id='linkReportsPos' to='/reports/pos' title='POS' hasBullet={true} />
        )}
      </AsideMenuItemWithSub>
    </>
  )
}
