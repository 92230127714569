import { FC } from 'react'
import MaskedNumberField from './MaskedNumberField'

type Props = {
  id: string
  name: string
  placeholder?: string
  className?: string
  disabled?:boolean
}

const ZipCodeField: FC<Props> = (props) => {
  return <MaskedNumberField {...props} maxLength={5} allowNegative={false} decimalScale={0} />
}

export default ZipCodeField
