import {gql} from '@apollo/client'

export const CREATE_PAYMENT = gql`
  mutation CreatePaymentMethod($model: CardPaymentMethodInput!) {
    createPaymentMethod2(input: $model) {
      errors
      paymentMethodId
      status
    }
  }
`

export const GET_PAYMENTS = gql`
  query GetPayments($customerId: UUID!) {
    paymentMethods(
      orderBy: IS_PRIMARY_DESC
      condition: {customerId: $customerId}
      filter: {deletedAt: {isNull: true}}
    ) {
      nodes {
        id
        isPrimary
        card {
          expMonth
          expYear
          last4
          brand
        }
        address {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          state {
            code
          }
          country {
            code
          }
          zipCode
        }
      }
    }
  }
`

export const CREATE_CARD_PAYMENT_METHOD = gql`
  mutation CreateCardPaymentMethod($payment: CardPaymentMethodCreateInput!) {
    cardPaymentMethodCreate(input: $payment) {
      id
    }
  }
`
export const CHECK_PAYMENT_METHOD = gql`
  query CheckPaymentMethod($customerId: UUID!, $paymentMethodId: UUID!) {
    checkPaymentMethod(pCustomerId: $customerId, paymentMethodId: $paymentMethodId)
  }
`
