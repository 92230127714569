import apolloClient from 'apollo-client'
import {
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  CHECK_CUSTOMER_EMAIL_EXIST,
  GET_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
  GET_CUSTOMER_CUSTOMER_BY_ID,
  CREATE_WINECLUB_SUBSCRIPTION,
  GET_WINE_CLUB_MEMBERS,
  FETCH_CUSTOMER_WINE_CLUB_MEMBERSHIPS,
  GET_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_NOTE,
  CREATE_CUSTOMER_SPOUSE,
  DELETE_SPOUSE,
  GET_PICKUP_LOCATIONS,
  GET_CUSTOMERS_TOTAL_COUNT,
} from './graphql'
import map from 'lodash/map'
import omit from 'lodash/omit'
import {mapToPickupLocation} from '../WineClubMembershipService/helper'
import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_NOTIFICATION,
} from 'app/features/customers/graphql/customer'
import GraphqlService from '../GraphqlService'

class CustomerService extends GraphqlService {
  async getCustomers(params: any, signal: any) {
    const {data, errors} = await this._signalQuery(GET_CUSTOMERS, signal, params)
    if (errors) {
      throw errors
    }
    return data.searchCustomersV2.nodes
  }

  async getCustomersTotalCount(params: any) {
    const {data, errors} = await this._query(GET_CUSTOMERS_TOTAL_COUNT, params)
    if (errors) {
      throw errors
    }
    return data.searchCustomersTotalCount
  }

  async createCustomer(customer: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_CUSTOMER,
      variables: {customer},
    })
    if (errors) {
      throw errors
    }
    const response = data.createCustomer.customer
    this.customerCreateNotify(response.id)
    return response
  }

  async customerCreateNotify(id: any) {
    await apolloClient.mutate({
      mutation: CREATE_CUSTOMER_NOTIFICATION,
      variables: {id},
    })
  }

  async deleteCustomer(customerId: any) {
    return await apolloClient.mutate({
      mutation: DELETE_CUSTOMER,
      variables: customerId,
    })
  }

  async checkEmailExist({id, email}: any) {
    const model = {
      id,
      email,
    }
    const result = await apolloClient.query({
      query: CHECK_CUSTOMER_EMAIL_EXIST,
      variables: model,
    })
    return result.data.customerEmailExist
  }

  async getCustomerAddresses(customerId: any) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_ADDRESS,
      variables: {customerId},
    })
    return data.customerAddresses.nodes
  }

  async getCustomerDetails(params: any) {
    const {data, errors} = await apolloClient.query({
      query: GET_CUSTOMER_DETAILS,
      variables: {id: params.id, orderBy: params.orderBy},
    })
    if (errors) {
      throw errors
    }
    return mapToCustomerOverViewModel(data)
  }

  async fetchCustomerAddress(id: any) {
    const {data} = await apolloClient.query({
      query: FETCH_CUSTOMER_ADDRESS,
      variables: {
        id,
      },
    })
    const {__typename, ...address} = data.customerAddress
    return address
  }

  async fetchCustomerSubscribedWineClubs(id: any) {
    const {data, errors} = await apolloClient.query({
      query: FETCH_CUSTOMER_WINE_CLUB_MEMBERSHIPS,
      variables: {
        id,
      },
    })
    if (errors) {
      throw errors
    }
    const result = map(data.wineClubSubscriptions.nodes, 'wineClub')
    return result
  }

  async fetchWineClubs() {
    const {data, errors} = await apolloClient.query({
      query: GET_WINE_CLUB_MEMBERS,
    })
    if (errors) {
      throw errors
    }
    return data.wineClubs.nodes
  }

  async createWineClubSubscription(params: any) {
    return await apolloClient.mutate({
      mutation: CREATE_WINECLUB_SUBSCRIPTION,
      variables: {
        customerId: params.customerId,
        wineClubId: params.wineClubId,
        orderDeliveryMethod: params.deliveryMethod,
        shippingAddressId: params.shippingAddress,
        paymentMethodId: params.payment,
        locationId: params.locationId,
      },
    })
  }

  async createAddress(address: any) {
    const {data} = await apolloClient.mutate({
      mutation: CREATE_CUSTOMER_ADDRESS,
      variables: {address},
    })
    const {customerAddress} = data.createCustomerAddress
    return customerAddress
  }

  async deleteAddress(id: any) {
    return await apolloClient.mutate({
      mutation: DELETE_CUSTOMER_ADDRESS,
      variables: {id},
    })
  }

  async updateAddress(address: any) {
    address = {...omit(address, ['isLinkedWithPayment'])}
    await apolloClient.mutate({
      mutation: UPDATE_CUSTOMER_ADDRESS,
      variables: {address: address, id: address.id},
    })
  }

  async updateNote(id: any, note: any) {
    await apolloClient.mutate({
      mutation: UPDATE_CUSTOMER_NOTE,
      variables: {id, note},
    })
  }

  async getCustomerById(id: string) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_CUSTOMER_BY_ID,
      variables: {
        id,
      },
    })
    return mapToPaymentAndAddressModel(data)
  }

  async createSpouse(spouseDetails: any) {
    return await apolloClient.mutate({
      mutation: CREATE_CUSTOMER_SPOUSE,
      variables: {spouse: spouseDetails},
    })
  }
  async deleteSpouse(id: any) {
    return await apolloClient.mutate({
      mutation: DELETE_SPOUSE,
      variables: {id},
    })
  }

  async getPickupLocations() {
    const {data} = await apolloClient.query({
      query: GET_PICKUP_LOCATIONS,
    })
    return mapToPickupLocation(data.fulfillmentLocations.nodes)
  }
}

const customerService = new CustomerService()
export default customerService

const mapToCustomerOverViewModel = (data: any) => {
  const customer = mapToPaymentAndAddressModel(data)
  const {nodes: wineClubSubscriptions} = data.wineClubSubscriptions
  const {nodes: orders} = customer.orders
  const {nodes: customerTags} = customer.customerTags
  return {...customer, orders, wineClubSubscriptions, customerTags}
}

const mapToPaymentAndAddressModel = (data: any) => {
  let defaultAddress = {}
  let paymentMethod = {}
  const {customer} = data
  const {nodes: customerAddresses} = customer.customerAddresses
  const {nodes: paymentMethods} = customer.paymentMethods
  if (paymentMethods && paymentMethods.length) {
    paymentMethod = paymentMethods[0]
  }
  if (customerAddresses && customerAddresses.length) {
    defaultAddress = customerAddresses[0]
  }
  return {...customer, paymentMethod, defaultAddress}
}
