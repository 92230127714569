import {gql} from '@apollo/client'

export const SEARCH_CUSTOMER = gql`
  query SearchCustomer($search: String!) {
    searchCustomersInOrder(customerName: $search, pEmail: $search, pPhone: $search) {
      nodes {
        id
        firstName
        lastName
        email
        phone
        hasWineClub
        customerAddresses(orderBy: IS_PRIMARY_DESC, first: 1, filter: {deletedAt: {isNull: true}}) {
          nodes {
            id
            addressLine1
            addressLine2
            city
            firstName
            state {
              code
            }
            country {
              code
            }
            zipCode
          }
        }
        paymentMethods(orderBy: IS_PRIMARY_DESC, filter: {deletedAt: {isNull: true}}, first: 1) {
          nodes {
            id
            isPrimary
            card {
              expMonth
              expYear
              last4
              brand
            }
            address {
              id
              firstName
              lastName
              addressLine1
              addressLine2
              city
              state {
                code
              }
              country {
                code
              }
              zipCode
            }
          }
        }
      }
    }
  }
`
