import * as yup from 'yup'
import range from 'lodash/range'
import AddressModel from './AddressModel'
import PaymentModel from './PaymentModel'

export type StorePaymentModel = {
  id?: string
  hasAddress?: boolean
  address: AddressModel
  payment: PaymentModel
  addressId?: string
}

export const storePaymentInitialState: StorePaymentModel = {
  address: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateId: '',
    countryId: '',
    zipCode: '',
  },

  payment: {expMonth: '', expYear: '', cvv: '', number: '', isDefault: false},
}

const addressSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .max(199, 'First name should not exceed 200 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(199, 'Last name should not exceed 200 characters'),
  addressLine1: yup
    .string()
    .required('Address line 1 is required')
    .max(199, 'Address should not exceed 200 characters'),
  addressLine2: yup.string().max(199, 'Address should not exceed 200 characters'),
  city: yup.string().required('City is required').max(199, 'City should not exceed 200 characters'),
  countryId: yup.string().required('Country is required'),
  stateId: yup.string().required('State is required'),
  zipCode: yup.string().min(5, 'Please enter valid zip code').required('Zip code is required'),
})

export const PaymentDetailsSchema = yup.object().shape({
  address: yup.object().when('hasAddress', {
    is: false,
    then: addressSchema,
    otherwise: yup.object().notRequired(),
  }),
  addressId: yup.string().when('hasAddress', {
    is: true,
    then: yup.string().required('Select address'),
    otherwise: yup.string().notRequired(),
  }),
  payment: yup.object().shape({
    number: yup
      .string()
      .trim()
      .min(18, 'Please enter valid card number')
      .required('Please enter a Card Number'),
    expMonth: yup.string().required(' Select a Month '),
    expYear: yup.string().required(' Select a Year '),
    cvv: yup
      .string()
      .trim()
      .min(3, 'CVV not less than 3')
      .max(4, 'CVV not greater than 4')
      .required('Enter a CVV Number'),
  }),
})

export default StorePaymentModel

export const Month = range(1, 13)
const date = new Date()
const year = date.getFullYear()
export const YearRanges = range(year, year + 10)
