//@ts-nocheck
import moment from 'moment'
import * as yup from 'yup'

export type Address = {
  firstName: string
  lastName: string
  addressLine1: string
  addressLine2: string
  city: string
  stateId: string
  countryId: string
  zipCode: string
  phone: string
  isPrimary: boolean
}

export type Payment = {
  expMonth: number
  expYear: number
  cvv: number
  number: number
}

type Customer = {
  id?: string
  isEdit?: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  acceptsMarketing: boolean
  address: Address
  payment: Payment
  tagIds?: any[]
  note?: string
  classIds?: any[]
  isEnabledCustomerMethods?: boolean
}

export type CustomerSpouse = {
  id?: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

export type CustomerElavonModel = {
  id?: string
  isEdit?: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  acceptsMarketing: boolean
  address: Address
  tagIds?: any[]
  note?: string
  classIds?: any[]
  isEnabledCustomerMethods?: boolean
}

export type CustomerWineClub = {
  wineClubId: string
  deliveryMethod: string
  shippingAddress: string
  billingAddress: string
  payment: string
  locationId?: string
}

export default Customer

export const addressInitialValues = {
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  stateId: '',
  countryId: '',
  zipCode: '',
  phone: '',
  dateOfBirth: '',
  isPrimary: false,
}

export const paymentMethodInitialState: Payment = {
  expMonth: '',
  expYear: '',
  cvv: '',
  number: '',
}

export const customerInitialValues: Customer = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  tagIds: [],
  classIds: [],
  note: '',
  acceptsMarketing: false,
  address: addressInitialValues,
  payment: paymentMethodInitialState,
  isEdit: false,
  isEnabledCustomerMethods: false,
}

export const customerElavonInitialValues: CustomerElavonModel = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  tagIds: [],
  classIds: [],
  note: '',
  acceptsMarketing: false,
  address: addressInitialValues,
  isEdit: false,
  isEnabledCustomerMethods: false,
}

export const spouseInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

export const customerWineClubInitialValues: CustomerWineClub = {
  wineClubId: '',
  deliveryMethod: 'SHIP',
  shippingAddress: '',
  payment: '',
  locationId: '',
}

export const customerWineClubSchema = yup.object().shape({
  wineClubId: yup.string().required('Wine Club is required'),
  deliveryMethod: yup.string().required('Delivery Method is required'),
  shippingAddress: yup.string().required('Shipping Address is required'),
  payment: yup.string().required('Payment Method is required'),
})

export const AddressSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .required('Address line 1 is required')
    .max(199, 'Address should not exceed 200 characters'),
  addressLine2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .max(199, 'Address should not exceed 200 characters'),
  city: yup.string().required('City is required').max(199, 'City should not exceed 200 characters'),
  countryId: yup.string().required('Country is required'),
  stateId: yup.string().required('State is required'),
  zipCode: yup.string().min(5, 'Please enter valid zip code').required('Zip code is required'),
  dateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .typeError('Date of birth is required')
    .test(function (value) {
      const {path, createError} = this
      if (!value) {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }
      const dob = moment(value, 'MM-DD-YYYY')
      if (dob.isValid()) {
        const age = moment(new Date()).diff(dob, 'years')
        if (age < 21) {
          return createError({
            path,
            message: 'Date birth is not valid, should be more than 21 years',
          })
        }
      } else {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }

      return true
    }),
  phone: yup
    .string()
    .test(function (value) {
      const {path, createError} = this
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid phone number',
        })
      }
      return true
    })
    .required('Phone is required'),
})

export const PaymentMethodSchema = yup.object().shape({
  number: yup
    .string()
    .trim()
    .min(18, 'Please enter valid card number')
    .required('Please enter a Card Number'),
  expMonth: yup.string().required(' Select a Month '),
  expYear: yup.string().required(' Select a Year '),
  cvv: yup
    .string()
    .min(3, 'CVV not less than 3')
    .max(4, 'CVV not greater than 4')
    .required('Enter a CVV Number'),
})

export const CustomerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .max(199, 'First name should not exceed 200 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(199, 'Last name should not exceed 200 characters'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().test(function (value) {
    const {path, createError} = this
    if (!value) {
      return true
    }
    const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
    if (checkPhoneNumberLength(value).length <= isCheck) {
      return createError({
        path,
        message: 'Please enter valid phone number',
      })
    }
    return true
  }),
  address: yup.object().when(['isEdit', 'isEnabledCustomerMethods'], {
    is: (isEdit, isEnabledCustomerMethods) => !isEdit && isEnabledCustomerMethods,
    then: AddressSchema,
    otherwise: yup.object().notRequired(),
  }),
  payment: yup.object().when(['isEdit', 'isEnabledCustomerMethods'], {
    is: (isEdit, isEnabledCustomerMethods) => !isEdit && isEnabledCustomerMethods,
    then: PaymentMethodSchema,
    otherwise: yup.object().notRequired(),
  }),
})

export const CustomerElavonSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .max(199, 'First name should not exceed 200 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(199, 'Last name should not exceed 200 characters'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().test(function (value) {
    const {path, createError} = this
    if (!value) {
      return true
    }
    const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
    if (checkPhoneNumberLength(value).length <= isCheck) {
      return createError({
        path,
        message: 'Please enter valid phone number',
      })
    }
    return true
  }),

  address: yup.object().when(['isEdit', 'isEnabledCustomerMethods'], {
    is: (isEdit, isEnabledCustomerMethods) => !isEdit && isEnabledCustomerMethods,
    then: AddressSchema,
    otherwise: yup.object().notRequired(),
  }),
})

export const checkPhoneNumberLength = (value: any) => {
  let phone
  const removeBrackets = _.replace(value, /[()-]/g, '')
  const removeUnderScores = _.replace(removeBrackets, /_/g, '')
  phone = _.replace(removeUnderScores, /\s/g, '')
  return phone
}

export type AddressModel = {
  id?: string
  address: Address
}

export const addressDefaultValues: AddressModel = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: addressInitialValues,
}

export const addressValidationSchema: AddressModel = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .max(199, 'First name should not exceed 200 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(199, 'Last name should not exceed 200 characters'),
  email: yup.string().email('Email is invalid').required('Email is required').nullable(),
  phone: yup
    .string()
    .test(function (value) {
      const {path, createError} = this
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid phone number',
        })
      }
      return true
    })
    .required('Phone is required'),

  address: AddressSchema.pick([
    'addressLine1',
    'addressLine2',
    'city',
    'countryId',
    'stateId',
    'zipCode',
    'dateOfBirth',
  ]),
})

export const addressCreate: Address = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .max(199, 'First name maximum 200 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required')
    .max(198, 'Last name maximum 200 characters'),
  // addressLine1: yup.string()
  //   .trim()
  //   .required('Address  is required')
  //   .max(199, 'Address maximum 200 characters'),
  city: yup.string().trim().required('City is required').max(199, 'City  maximum 200 characters'),
  stateId: yup.string().trim().required('State is required'),
  countryId: yup.string().required('Country is required'),
  // email: yup.string().email('Email is invalid').required('Email is required').nullable(),
  phone: yup
    .string()
    .trim()
    .test(function (value) {
      const {path, createError} = this
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid Phone',
        })
      }
      return true
    })
    .required('Phone is required'),
  zipCode: yup.string().trim().required('Zip code is required'),
  dateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .typeError('Date of birth is required')
    .test(function (value) {
      const {path, createError} = this
      if (!value) {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }
      const dob = moment(value, 'MM-DD-YYYY')
      if (dob.isValid()) {
        const age = moment(new Date()).diff(dob, 'years')
        if (age < 21) {
          return createError({
            path,
            message: 'Date birth is not valid, should be more than 21 years',
          })
        }
      } else {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }

      return true
    }),
})

export const CustomerSpouseSchema = yup.object().shape({
  customers: yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required('First Name is required')
      .max(200, 'First Name should not exceed 200 characters'),
    lastName: yup
      .string()
      .trim()
      .required('Last Name is required')
      .max(200, 'Last Name should not exceed 200 characters'),
    phone: yup.string().test(function (value) {
      const {path, createError} = this
      if (!value || value.trim() === '') {
        return true
      }
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid Phone',
        })
      }
      return true
    }),
  }),
  spouses: yup.object().shape({
    firstName: yup.string().max(199, 'First Name should not exceed 200 characters'),
    lastName: yup.string().max(199, 'Last Name should not exceed 200 characters'),
    email: yup.string().email('Email is invalid'),
    phone: yup.string().test(function (value) {
      const {path, createError} = this
      if (!value || value.trim() === '') {
        return true
      }
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid Phone',
        })
      }
      return true
    }),
  }),
})

export const spouseSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(199, 'First Name should not exceed 200 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .max(199, 'Last Name should not exceed 200 characters')
    .required('Last Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().test(function (value) {
    const {path, createError} = this
    const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
    if (checkPhoneNumberLength(value).length <= isCheck) {
      return createError({
        path,
        message: 'Please enter valid Phone',
      })
    }
    return true
  }),
})
