import {FC} from 'react'
import {TextField, PhoneField} from '_core/forms'
import clsx from 'clsx'

interface Props {
  disabled?: boolean
  isPhoneIsNotRequired?: boolean
}

const CustomerDetail: FC<Props> = ({disabled, isPhoneIsNotRequired}) => {
  return (
    <>
      <div className='row mb-10'>
        <div className='col-xl-6'>
          <label className='form-label fs-6 fw-bold text-dark required'>First Name</label>
          <TextField id='txtCustomerOverviewFirstName' name='firstName' maxLength={200} />
        </div>
        <div className='col-xl-6 '>
          <label className='form-label fs-6 fw-bold text-dark required'>Last Name</label>
          <TextField id='txtCustomerOverviewLastName' name='lastName' maxLength={200} />
        </div>
      </div>
      <div className='row mb-10'>
        <div className='col-xl-6'>
          <label className='form-label fs-6 fw-bold text-dark required'>Email</label>
          <TextField
            id='txtCustomerOverviewEmail'
            name='email'
            maxLength={200}
            disabled={disabled}
          />
        </div>
        <div className='col-xl-6'>
          <label
            className={clsx(
              'form-label fs-6 fw-bold text-dark',
              !isPhoneIsNotRequired && 'required'
            )}
          >
            Phone
          </label>
          <PhoneField id='phnCustomerPhone' name='phone' />
        </div>
      </div>
    </>
  )
}

export default CustomerDetail
