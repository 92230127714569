import {gql} from '@apollo/client'

export const GET_CUSTOMERS = gql`
  query SearchCustomersV2(
    $customerType: String
    $tagIds: [UUID]
    $payment: Boolean
    $password: Boolean
    $account: Boolean
    $hasWineClub: Boolean
    $orderBy: String
    $name: String
    $offset: Int!
    $pageSize: Int!
  ) {
    searchCustomersV2(
      pCustomerType: $customerType
      pCustomerTag: $tagIds
      pHasPaymentMethod: $payment
      pHasPassword: $password
      pHasAccount: $account
      pHasWineClub: $hasWineClub
      orderBy: $orderBy
      searchText: $name
      pPageSize: $pageSize
      pOffset: $offset
    ) {
      nodes {
        id
        firstName
        lastName
        isActive
        totalOrders
        totalSpent
        averageOrderValue
        hasAccount
        hasAddress
        hasPassword
        hasPaymentMethod
        hasWineClub
        email
        phone
        customerType
        customerTags
      }
    }
  }
`

export const GET_CUSTOMERS_TOTAL_COUNT = gql`
  query SearchCustomersTotalCount(
    $tagIds: [UUID]
    $customerType: String
    $account: Boolean
    $password: Boolean
    $payment: Boolean
    $hasWineClub: Boolean
    $name: String
  ) {
    searchCustomersTotalCount(
      pCustomerTag: $tagIds
      pCustomerType: $customerType
      pHasAccount: $account
      pHasPassword: $password
      pHasPaymentMethod: $payment
      pHasWineClub: $hasWineClub
      searchText: $name
    )
  }
`

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: UUID!) {
    deleteCustomer(input: {id: $id}) {
      clientMutationId
    }
  }
`

export const CHECK_CUSTOMER_EMAIL_EXIST = gql`
  query CheckCustomerEmailExist($id: UUID, $email: String!) {
    customerEmailExist(customerId: $id, pEmail: $email)
  }
`
export const GET_CUSTOMER_ADDRESS = gql`
  query GetCustomerAddresses($customerId: UUID!) {
    customerAddresses(
      orderBy: IS_PRIMARY_DESC
      filter: {customerId: {equalTo: $customerId}, deletedAt: {isNull: true}}
    ) {
      nodes {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        city
        stateId
        countryId
        customerId
        phone
        zipCode
        dateOfBirth
        isPrimary
        isLinkedWithPayment
        isShippingAddress
        isBillingAddress
        country {
          name
          code
        }
        state {
          name
          code
        }
      }
    }
  }
`

export const GET_CUSTOMER_DETAILS = gql`
  query GetCustomer($id: UUID!, $orderBy: [OrdersOrderBy!]) {
    customer(id: $id) {
      name
      email
      phone
      totalSpent
      customerAddresses(orderBy: IS_PRIMARY_DESC, first: 1, filter: {deletedAt: {isNull: true}}) {
        nodes {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          zipCode
          state {
            name
          }
          country {
            name
          }
        }
      }
      orders(orderBy: $orderBy) {
        nodes {
          id
          customerId
          orderNumber
          itemCount
          fulfillmentStatus
          financialStatus
          orderType
          status
          createdAt
          totalPrice
          isAdminEnabledPosCancel
        }
        totalCount
      }
      paymentMethods(orderBy: IS_PRIMARY_DESC, filter: {deletedAt: {isNull: true}}, first: 1) {
        nodes {
          id
          card {
            brand
            last4
            expMonth
            expYear
          }
        }
      }
      acceptsMarketing
      note
      customerTags {
        nodes {
          tagId
          id
        }
      }
    }
    wineClubSubscriptions: getWineClubMembershipByCustomer(pCustomerId: $id) {
      nodes {
        subscriptionId
        sinceYear
        sinceMonth
        sinceDay
        name
        lastName
        firstName
        customerTotal
        completedOrders
        status
      }
      totalCount
    }
  }
`

export const FETCH_CUSTOMER_ADDRESS = gql`
  query GetAddress($id: UUID!) {
    customerAddress(id: $id) {
      id
      firstName
      lastName
      email
      phone
      addressLine1
      addressLine2
      city
      countryId
      stateId
      zipCode
      customerId
      isLinkedWithPayment
      dateOfBirth
      isPrimary
    }
  }
`

export const FETCH_CUSTOMER_WINE_CLUB_MEMBERSHIPS = gql`
  query WineClubSubscriptions($id: UUID) {
    wineClubSubscriptions(filter: {customerId: {equalTo: $id}, status: {in: [ACTIVE, PAUSE]}}) {
      nodes {
        wineClub {
          name
          id
        }
      }
    }
  }
`

export const GET_WINE_CLUB_MEMBERS = gql`
  query GetWineClubs {
    wineClubs(filter: {status: {equalTo: ACTIVE}, deletedAt: {isNull: true}}) {
      nodes {
        id
        name
        status
      }
    }
  }
`

export const CREATE_WINECLUB_SUBSCRIPTION = gql`
  mutation CreateCustomerWineClubSubscription(
    $customerId: UUID!
    $orderDeliveryMethod: OrderDeliveryMethod!
    $paymentMethodId: UUID!
    $shippingAddressId: UUID!
    $wineClubId: UUID!
    $locationId: UUID
  ) {
    createCustomerWineClubSubscription(
      input: {
        pCustomerId: $customerId
        pShippingAddressId: $shippingAddressId
        pWineClubId: $wineClubId
        pPaymentMethodId: $paymentMethodId
        pDeliveryMethod: $orderDeliveryMethod
        pLocationId: $locationId
      }
    ) {
      clientMutationId
    }
  }
`

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($address: CustomerAddressInput!) {
    createCustomerAddress(input: {customerAddress: $address}) {
      clientMutationId
      customerAddress {
        id
      }
    }
  }
`

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation DeleteCustomerAddress($id: UUID!) {
    deleteCustomerAddress(input: {id: $id}) {
      clientMutationId
    }
  }
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($address: CustomerAddressPatch!, $id: UUID!) {
    updateCustomerAddress(input: {patch: $address, id: $id}) {
      clientMutationId
    }
  }
`

export const UPDATE_CUSTOMER_NOTE = gql`
  mutation UpdateCustomerNote($id: UUID!, $note: String) {
    updateCustomer(input: {patch: {note: $note}, id: $id}) {
      clientMutationId
    }
  }
`

export const GET_CUSTOMER_CUSTOMER_BY_ID = gql`
  query GetSearchCustomer($id: UUID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      email
      phone
      customerAddresses(orderBy: IS_PRIMARY_DESC, first: 1, filter: {deletedAt: {isNull: true}}) {
        nodes {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          zipCode
          state {
            name
            code
          }
          country {
            name
            code
          }
        }
      }
      paymentMethods(orderBy: IS_PRIMARY_DESC, filter: {deletedAt: {isNull: true}}, first: 1) {
        nodes {
          id
          isPrimary
          card {
            expMonth
            expYear
            last4
            brand
          }
          address {
            id
            firstName
            lastName
            addressLine1
            addressLine2
            city
            state {
              code
            }
            country {
              code
            }
            zipCode
          }
        }
      }
    }
  }
`

export const CREATE_CUSTOMER_SPOUSE = gql`
  mutation CreateCustomerSpouse($spouse: CustomerSpouseInput!) {
    createCustomerSpouse(input: {customerSpouse: $spouse}) {
      clientMutationId
    }
  }
`
export const DELETE_SPOUSE = gql`
  mutation DeleteSpouse($id: UUID!) {
    deleteCustomerSpouse(input: {id: $id}) {
      clientMutationId
    }
  }
`

export const GET_PICKUP_LOCATIONS = gql`
  query FulfillmentLocations {
    fulfillmentLocations(filter: {orderType: {equalTo: WINE_CLUB}}) {
      nodes {
        isDefault
        pickupLocation {
          id
          name
          addressLine1
          addressLine2
          city
          country {
            code
          }
          zipCode
          state {
            name
          }
        }
      }
    }
  }
`
