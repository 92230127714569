import axios from 'axios'
import {gql} from '@apollo/client'
import apolloClient from 'apollo-client'
import {UserModel} from '../models/UserModel'
import {UPDATE_STORE_NAME} from '../../settings/graphql/store'
import {GET_LOGGED_IN_USER} from '../query/auth'
import storeService from 'app/services/StoreService/StoreService'
export const GET_USER_BY_ACCESS_TOKEN_URL = '/auth/get-user'
export const LOGIN_URL = `/users/v1/auth/login`
export const ADMIN_CLIENT_LOGIN_URL = `/users/v1/auth/admin-client-login`
export const TOKEN_RENEW_URL = `/users/v1/auth/renew`
export const REQUEST_PASSWORD_URL = `/users/v1/auth/forgot-password`
export const STORE_URL = `/users/v1/public/store/register`
const CONFIG_URL = `/store/admin/v1/payment/accounts/config`

const ACCESS_TOKEN_KEY = 'accessToken'
const USER_KEY = 'user'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{error: any}>(REQUEST_PASSWORD_URL, {email})
}

export async function signInWithToken() {
  return axios.post(TOKEN_RENEW_URL)
}

// Server should return AuthModel
export function adminLogin(loginId: string) {
  return axios.post(ADMIN_CLIENT_LOGIN_URL, {loginId})
}

export async function getUserByToken() {
  const config = await fetchConfig()
  return new Promise<UserModel>((resolve, reject) => {
    apolloClient
      .query<UserModel>({
        query: gql`
          ${GET_LOGGED_IN_USER}
        `,
      })
      .then(({data}: any) => {
        let user: any = data.me
        if (data.shop && data.shop.storeSetups && data.shop.storeSetups.nodes) {
          const storeSetup = data.shop.storeSetups.nodes
          user.store = {
            id: data.shop.id,
            name: data.shop.name,
            domain: data.shop.domain,
            paymentProviderType: data.shop.paymentProviderType,
            setupId: storeSetup.length ? storeSetup[0].id : null,
            storePlan: data.shop.storePlan,
            storePlanId: data.shop.storePlan.id,
            config,
          }
          setSessionUser(user)
        }
        resolve(user)
      })
  })
}

export const setAccessToken = (accessToken: string, rememberMe: boolean) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  if (rememberMe) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
}

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const setSessionUser = (user: any) => {
  return window.sessionStorage.setItem(USER_KEY, JSON.stringify(user))
}

export const getSessionUser = () => {
  const usr = window.sessionStorage.getItem(USER_KEY)
  if (usr) {
    return JSON.parse(usr)
  }
  return null
}

export const updateStoreName = async (store: any) => {
  await apolloClient.mutate({
    mutation: UPDATE_STORE_NAME,
    variables: {
      id: store.id,
      store: {name: store.name},
    },
  })
}

const fetchConfig = async () => {
  const {data} = await axios.get(CONFIG_URL)
  return data
}
