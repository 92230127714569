import Service from '../Service'

class AnalyticsService extends Service {
  constructor() {
    super('/v1/analytics')
  }
  async url() {
    return this.post(null, '/security/url')
  }
}

const analyticsService = new AnalyticsService()
export default analyticsService
