import {StateLookup, CountryLookup} from 'app/components'
import {FC} from 'react'
import {TextField, ZipCodeField} from '_core/forms'
import {useFormikContext} from 'formik'
import DateOfBirth from 'app/components/DateOfBirth'
import AddressSuggestion from 'app/components/AddressSearch/AddressSuggestion'

type Props = {
  isHideTitle?: boolean
  children?: React.ReactNode
  isEnableDateOfBirth?: boolean
}

const AddressDetails: FC<Props> = ({isHideTitle, children, isEnableDateOfBirth}) => {
  const {
    values: {address},
  } = useFormikContext<any>()

  return (
    <div className='mb-xl-8'>
      {!isHideTitle && <div className='fs-4 fw-bolder text-dark mb-3 pb-5'>Billing Address</div>}
      <div className='row mb-10'>
        <div className='col-xl-6'>
          <label className='form-label fs-6 fw-bold text-dark required'>First Name</label>
          <TextField id='txtBillingAddressFirstName' name='address.firstName' maxLength={200} />
        </div>
        <div className='col-xl-6'>
          <label className='form-label fs-6 fw-bold text-dark required'>Last Name</label>
          <TextField
            id='txtBillingAddressAddressLastName'
            name='address.lastName'
            maxLength={200}
          />
        </div>
      </div>
      {children}

      <div className='fv-row mb-10'>
        <div className='col-xl-12'>
          <label className='form-label fs-6 fw-bold text-dark required'>Address</label>
          <AddressSuggestion
            id='txtCustomerAddress1'
            name='address.addressLine1'
            fieldName='address'
            className='py-2'
          />
          {/* <TextField id='txtBillingAddressAddress1' name='address.addressLine1' maxLength={200} /> */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-xl-6'>
            <label className='form-label fs-6 fw-bold text-dark'>Apartment, suite, etc.</label>
            <TextField id='txtBillingAddressAddress2' name='address.addressLine2' maxLength={200} />
          </div>
          <div className='col-xl-6'>
            <label className='form-label fs-6 fw-bold text-dark required'>City</label>
            <TextField id='txtBillingAddresscity' name='address.city' maxLength={200} />
          </div>
        </div>
      </div>
      {isEnableDateOfBirth ? (
        <>
          <div className='fv-row mb-10'>
            <div className='row'>
              <CountryLookup className='col-xl-6' name='address.countryId' />
              <StateLookup
                className='col-xl-6'
                name='address.stateId'
                countryId={address?.countryId}
              />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <div className='row'>
              <div className='col-xl-6'>
                <label className='form-label fs-6 fw-bold text-dark required'>ZIP Code</label>
                <ZipCodeField
                  id='zipCusZipCode'
                  name='address.zipCode'
                  className='form-control py-3'
                />
              </div>
              <div className='col-xl-6 mb-10'>
                <label className='form-label fs-6 fw-bold text-dark required'>Date of Birth</label>
                <DateOfBirth id='customerAddressDateOfBirth' name='address.dateOfBirth' />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='fv-row mb-10'>
          <div className='row'>
            <CountryLookup className='col-xl-4' name='address.countryId' />
            <StateLookup
              className='col-xl-4'
              name='address.stateId'
              countryId={address.countryId}
            />
            <div className='col-xl-4'>
              <label className='form-label fs-6 fw-bold text-dark required'>ZIP Code</label>
              <ZipCodeField
                id='zipBillingZipCode'
                name='address.zipCode'
                className='form-control py-3'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddressDetails
